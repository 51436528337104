.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
.text-center {
  justify-content: center;
}
// Colors
@brand-primary: #29b5a8;
@brand-secondary: #ff8300;
@gray: #6b7381;
@gray-light: lighten(@gray, 15%);
@gray-lighter: lighten(@gray, 30%);
@red:#F70D1A;

// Button Colors
@btn-default-color: @gray;
@btn-default-bg: @gray-lighter;

// Toggle Sizes
@toggle-default-size: 1.5rem;
@toggle-default-label-width: 4rem;
@toggle-default-font-size: .75rem;

.red, .red a{
  color: @red;
}
// Mixin for Switch Colors
// Variables: @color, @bg, @active-bg
.toggle-color(@color: @btn-default-color; @bg: @btn-default-bg; @active-bg: @brand-primary;) {
  color: @color;
  background: @bg;
  &:before,
  &:after {
    color: @color;
  }
  &.active {
    background-color: @active-bg;
  }
}

// Mixin for Default Switch Styles
// Variables: @size, @margin, @color, @bg, @active-bg, @font-size
.toggle-mixin(@size: @toggle-default-size; @margin: @toggle-default-label-width; @font-size: @toggle-default-font-size;) {
  // color: @color;
  // background: @bg;
  margin: 0 @margin;
  padding: 0;
  position: relative;
  border: none;
  height: @size;
  width: @size * 2;
  border-radius: @size;

  &:focus,
  &.focus {
    &,
    &.active {
      outline: none;
    }
  }

  &:before,
  &:after {
    line-height: @size;
    width: @margin;
    text-align: center;
    font-weight: 600;
    // color: @color;
    font-size: @font-size;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity .25s;
  }
  &:before {
    content: 'Off';
    left: -@margin;
  }
  &:after {
    content: 'On';
    right: -@margin;
    opacity: .5;
  }

  > .handle {
    position: absolute;
    top: (@size * .25) / 2;
    left: (@size * .25) / 2;
    width: @size * .75;
    height: @size * .75;
    border-radius: @size * .75;
    background: #fff;
    transition: left .25s;
  }
  &.active {
    transition: background-color .25s;
    > .handle {
      left: @size + ((@size * .25) / 2);
      transition: left .25s;
    }
    &:before {
      opacity: .5;
    }
    &:after {
      opacity: 1;
    }
  }

  &.btn-sm {
    &:before,
    &:after {
      line-height: @size - 2px;
      color: #fff;
      letter-spacing: .75px;
      left: @size * .275;
      width: @size * 1.55;
    }
    &:before {
      text-align: right;
    }
    &:after {
      text-align: left;
      opacity: 0;
    }
    &.active {
      &:before {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }
  }

  &.btn-xs {
    &:before,
    &:after {
      display: none;
    }
  }
}



// Apply Mixin to different sizes & colors
.btn-toggle {

  .toggle-mixin;
  .toggle-color;

  &.btn-lg {
    .toggle-mixin(@size: 2.5rem; @font-size: 1rem; @margin: 5rem;);
  }

  &.btn-sm {
    .toggle-mixin(@font-size: .55rem; @margin: .5rem;);
  }

  &.btn-xs {
    .toggle-mixin(@size:1rem;@margin:0;)
  }

  &.btn-secondary {
    .toggle-color(@active-bg:@brand-secondary);
  }
}

.navbar>.container .navbar-brand{
  margin-top: -10px;
}
select[name="filter"]{ width:100%; max-width:300px;}
.delete-link{
  float: right;
  padding-top: 0;
  padding: 0;
}
.giftform{
  .form-group{
    margin-bottom: 7px;
    label{ text-align: left; padding-left:0; padding-top: 2px;}
    &:last-child{ margin-bottom: 15px; }
    input{
      text-align: center;
    }
  }
}
.scroll-overflowx{
  overflow-x: scroll;
}
select[name="user_id"]{
  min-width:200px;
}
.masquerade{
  background: #E41B17;
  font-size:15px;
  line-height:25px;
  color: #222;
  a{
    color: #fff;
    text-decoration: underline;
  }
}
.import-scores{
  border-top:2px solid #dadada;
  margin-top:10px;
  form{
    margin-bottom:5px;
    button{
      padding-left: 0;
      border: 0;
    }
  }
}
.comment-wrapper{ height:300px; overflow-y: scroll; background: #fff; border-top:1px solid #ccc;}
.comment{ border-bottom:1px solid #ccc; padding:15px 5px;}
.comment .author{ font-size:11px;}
.comment .changed:not(:empty):before{content: 'Changed: '; font-weight:bold;}
.comment .text:not(:empty):before{content: 'Comments: '; font-weight:bold;}
.login-page, .register-page {background: #3c8dbc;}
.admin-dashboard button{ margin-bottom:10px;}
.admin-dashboard h1{font-size: 24px;}
.admin-dashboard .btn-lg{ font-size:24px;}
.content-header {padding: 15px 0;}
.content{padding: 0;}
.content-header h1.inline-block{ display: inline-block;}
.content-header .created{display: inline-block; padding-left:10px;}
.content-header .print-page{
  float: right;
}
.dropdown-menu i.fa {
  width: 20px;
  text-align: center;
}
.ministry{
  border:1px solid #dadada;
  margin-bottom:10px;
  .name{
    background: #ccc;
    font-size: 15px;
    line-height: 25px;
    border-bottom: 1px solid #dadada;
    padding-left:10px;
  }
  .body{
    padding:0 10px 10px 10px;
    .desc{ padding:2px 0;}
  }
}
.layout-top-nav .content-wrapper{
  padding-bottom:30px;
}
.questionnumber{
  margin-bottom:35px;
}
.answers{
  margin-top:35px;
  /*background: #fff;
  padding:20px;
  display: inline-block;*/
}
.complete-container:not(.complete){
  display: none;
}
.complete-container{
  i{
    padding-right:10px;
  }
  a{
    color: #fff;
  }
}
.question-container{
  .autosubmit{
    margin-bottom:40px;
  }
  .postanswer{
    margin-top:30px;
  }
  .saving{
    margin-top: 20px;
    display: none;
  }
}
ul#questions{
  list-style: none;
  padding-left:0;
  counter-reset: counter;
  li{
    background-color: #fff;
    padding: 10px;
  }
  li:before{
    content: counter(counter);
    counter-increment: counter;
    padding-right:10px;
    display: inline-block;
    width: 25px;
    text-align: center;
  }
  li .fa{ padding-right:10px; cursor: move}
}
#ministries_wrapper td span.label{
  width:50px;
  display: inline-block;
}
.collect-talents{
 [type="submit"]{
    margin-top:20px;
  }
}
@media screen and (max-width:640px){
  .question-container{
    .questionnumber {
      margin-bottom: 20px;
    }
    .autosubmit {
      margin-bottom: 20px;
    }
    form.answers > label{
      width: 100%;
      display: block;
      text-align: left;
      clear: both;
      margin-left: 0;
      margin-bottom: 10px;
      border: 1px solid #dadada;
      padding-left: 25px;
      border-radius: 5px;
      font-size: 20px;
      padding-top: 2px;
      padding-bottom: 2px;
      &:hover {
        cursor: pointer;
      }
      input[type="radio"]{
        margin-top: 8px;
      }
    }
  }
}
#summaryChart{
  background: #fff;
}
@media print{
  .col-print-1 {width:8%;  float:left;}
  .col-print-2 {width:16%; float:left;}
  .col-print-3 {width:25%; float:left;}
  .col-print-4 {width:33%; float:left;}
  .col-print-5 {width:42%; float:left;}
  .col-print-6 {width:50%; float:left;}
  .col-print-7 {width:58%; float:left;}
  .col-print-8 {width:66%; float:left;}
  .col-print-9 {width:75%; float:left;}
  .col-print-10{width:83%; float:left;}
  .col-print-11{width:92%; float:left;}
  .col-print-12{width:100%; float:left;}
  .ministry{page-break-inside: avoid;}
  .content-header {
    display: block !important;
  }
  .content{ padding-left:0;}
  .content-header h1{ display: inline-block;}
  .created{
    font-size: 18px;
    margin-left: 10px;
    display: inline-block;
  }
  .table.giftstable>tbody>tr:nth-child(-n+3) td{
    background: #f4f70e !important;
    font-weight:bold;
  }
  .remove-left-pad-print{ padding-left:0;}
  .table.giftstable>tbody>tr>td, .table.giftstable>tbody>tr>th, .table.giftstable>tfoot>tr>td, .table.giftstable>tfoot>tr>th, .table.giftstable>thead>tr>td, .table.giftstable>thead>tr>th{
    padding: 3px;
  }
  .collect-talents{
    div[class*="icheckbox"]{
      border:1px solid #ccc;
    }
    [data-toggle="tooltip"]{
      display: none;
    }
    .print-name{
      display: inline-block !important;
      margin-bottom:20px;
      &:after{
        content: ' ';
        display: inline-block;
        border-bottom:1px solid #ccc;
        width:500px;
        margin-left:5px;
      }
    }
  }
}
