.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh;
  /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}
.text-center {
  justify-content: center;
}
.red,
.red a {
  color: #F70D1A;
}
.btn-toggle {
  margin: 0 4rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}
.btn-toggle:before,
.btn-toggle:after {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle:before {
  content: 'Off';
  left: -4rem;
}
.btn-toggle:after {
  content: 'On';
  right: -4rem;
  opacity: .5;
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left .25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left .25s;
}
.btn-toggle.active:before {
  opacity: .5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: .75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}
.btn-toggle:before,
.btn-toggle:after {
  color: #6b7381;
}
.btn-toggle.active {
  background-color: #29b5a8;
}
.btn-toggle.btn-lg {
  margin: 0 5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 2.5rem;
  width: 5rem;
  border-radius: 2.5rem;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active {
  outline: none;
}
.btn-toggle.btn-lg:before,
.btn-toggle.btn-lg:after {
  line-height: 2.5rem;
  width: 5rem;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle.btn-lg:before {
  content: 'Off';
  left: -5rem;
}
.btn-toggle.btn-lg:after {
  content: 'On';
  right: -5rem;
  opacity: .5;
}
.btn-toggle.btn-lg > .handle {
  position: absolute;
  top: 0.3125rem;
  left: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 1.875rem;
  background: #fff;
  transition: left .25s;
}
.btn-toggle.btn-lg.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-lg.active > .handle {
  left: 2.8125rem;
  transition: left .25s;
}
.btn-toggle.btn-lg.active:before {
  opacity: .5;
}
.btn-toggle.btn-lg.active:after {
  opacity: 1;
}
.btn-toggle.btn-lg.btn-sm:before,
.btn-toggle.btn-lg.btn-sm:after {
  line-height: 0.5rem;
  color: #fff;
  letter-spacing: .75px;
  left: 0.6875rem;
  width: 3.875rem;
}
.btn-toggle.btn-lg.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-lg.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-lg.btn-xs:before,
.btn-toggle.btn-lg.btn-xs:after {
  display: none;
}
.btn-toggle.btn-sm {
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active {
  outline: none;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: 1.5rem;
  width: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.55rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle.btn-sm:before {
  content: 'Off';
  left: -0.5rem;
}
.btn-toggle.btn-sm:after {
  content: 'On';
  right: -0.5rem;
  opacity: .5;
}
.btn-toggle.btn-sm > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left .25s;
}
.btn-toggle.btn-sm.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-sm.active > .handle {
  left: 1.6875rem;
  transition: left .25s;
}
.btn-toggle.btn-sm.active:before {
  opacity: .5;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: .75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
  display: none;
}
.btn-toggle.btn-xs {
  margin: 0 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1rem;
  width: 2rem;
  border-radius: 1rem;
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
  outline: none;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  line-height: 1rem;
  width: 0;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: absolute;
  bottom: 0;
  transition: opacity .25s;
}
.btn-toggle.btn-xs:before {
  content: 'Off';
  left: 0;
}
.btn-toggle.btn-xs:after {
  content: 'On';
  right: 0;
  opacity: .5;
}
.btn-toggle.btn-xs > .handle {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background: #fff;
  transition: left .25s;
}
.btn-toggle.btn-xs.active {
  transition: background-color 0.25s;
}
.btn-toggle.btn-xs.active > .handle {
  left: 1.125rem;
  transition: left .25s;
}
.btn-toggle.btn-xs.active:before {
  opacity: .5;
}
.btn-toggle.btn-xs.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs.btn-sm:before,
.btn-toggle.btn-xs.btn-sm:after {
  line-height: -1rem;
  color: #fff;
  letter-spacing: .75px;
  left: 0.275rem;
  width: 1.55rem;
}
.btn-toggle.btn-xs.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-xs.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs.btn-xs:before,
.btn-toggle.btn-xs.btn-xs:after {
  display: none;
}
.btn-toggle.btn-secondary {
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle.btn-secondary:before,
.btn-toggle.btn-secondary:after {
  color: #6b7381;
}
.btn-toggle.btn-secondary.active {
  background-color: #ff8300;
}
.navbar > .container .navbar-brand {
  margin-top: -10px;
}
select[name="filter"] {
  width: 100%;
  max-width: 300px;
}
.delete-link {
  float: right;
  padding-top: 0;
  padding: 0;
}
.giftform .form-group {
  margin-bottom: 7px;
}
.giftform .form-group label {
  text-align: left;
  padding-left: 0;
  padding-top: 2px;
}
.giftform .form-group:last-child {
  margin-bottom: 15px;
}
.giftform .form-group input {
  text-align: center;
}
.scroll-overflowx {
  overflow-x: scroll;
}
select[name="user_id"] {
  min-width: 200px;
}
.masquerade {
  background: #E41B17;
  font-size: 15px;
  line-height: 25px;
  color: #222;
}
.masquerade a {
  color: #fff;
  text-decoration: underline;
}
.import-scores {
  border-top: 2px solid #dadada;
  margin-top: 10px;
}
.import-scores form {
  margin-bottom: 5px;
}
.import-scores form button {
  padding-left: 0;
  border: 0;
}
.comment-wrapper {
  height: 300px;
  overflow-y: scroll;
  background: #fff;
  border-top: 1px solid #ccc;
}
.comment {
  border-bottom: 1px solid #ccc;
  padding: 15px 5px;
}
.comment .author {
  font-size: 11px;
}
.comment .changed:not(:empty):before {
  content: 'Changed: ';
  font-weight: bold;
}
.comment .text:not(:empty):before {
  content: 'Comments: ';
  font-weight: bold;
}
.login-page,
.register-page {
  background: #3c8dbc;
}
.admin-dashboard button {
  margin-bottom: 10px;
}
.admin-dashboard h1 {
  font-size: 24px;
}
.admin-dashboard .btn-lg {
  font-size: 24px;
}
.content-header {
  padding: 15px 0;
}
.content {
  padding: 0;
}
.content-header h1.inline-block {
  display: inline-block;
}
.content-header .created {
  display: inline-block;
  padding-left: 10px;
}
.content-header .print-page {
  float: right;
}
.dropdown-menu i.fa {
  width: 20px;
  text-align: center;
}
.ministry {
  border: 1px solid #dadada;
  margin-bottom: 10px;
}
.ministry .name {
  background: #ccc;
  font-size: 15px;
  line-height: 25px;
  border-bottom: 1px solid #dadada;
  padding-left: 10px;
}
.ministry .body {
  padding: 0 10px 10px 10px;
}
.ministry .body .desc {
  padding: 2px 0;
}
.layout-top-nav .content-wrapper {
  padding-bottom: 30px;
}
.questionnumber {
  margin-bottom: 35px;
}
.answers {
  margin-top: 35px;
  /*background: #fff;
  padding:20px;
  display: inline-block;*/
}
.complete-container:not(.complete) {
  display: none;
}
.complete-container i {
  padding-right: 10px;
}
.complete-container a {
  color: #fff;
}
.question-container .autosubmit {
  margin-bottom: 40px;
}
.question-container .postanswer {
  margin-top: 30px;
}
.question-container .saving {
  margin-top: 20px;
  display: none;
}
ul#questions {
  list-style: none;
  padding-left: 0;
  counter-reset: counter;
}
ul#questions li {
  background-color: #fff;
  padding: 10px;
}
ul#questions li:before {
  content: counter(counter);
  counter-increment: counter;
  padding-right: 10px;
  display: inline-block;
  width: 25px;
  text-align: center;
}
ul#questions li .fa {
  padding-right: 10px;
  cursor: move;
}
#ministries_wrapper td span.label {
  width: 50px;
  display: inline-block;
}
.collect-talents [type="submit"] {
  margin-top: 20px;
}
@media screen and (max-width: 640px) {
  .question-container .questionnumber {
    margin-bottom: 20px;
  }
  .question-container .autosubmit {
    margin-bottom: 20px;
  }
  .question-container form.answers > label {
    width: 100%;
    display: block;
    text-align: left;
    clear: both;
    margin-left: 0;
    margin-bottom: 10px;
    border: 1px solid #dadada;
    padding-left: 25px;
    border-radius: 5px;
    font-size: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .question-container form.answers > label:hover {
    cursor: pointer;
  }
  .question-container form.answers > label input[type="radio"] {
    margin-top: 8px;
  }
}
#summaryChart {
  background: #fff;
}
@media print {
  .col-print-1 {
    width: 8%;
    float: left;
  }
  .col-print-2 {
    width: 16%;
    float: left;
  }
  .col-print-3 {
    width: 25%;
    float: left;
  }
  .col-print-4 {
    width: 33%;
    float: left;
  }
  .col-print-5 {
    width: 42%;
    float: left;
  }
  .col-print-6 {
    width: 50%;
    float: left;
  }
  .col-print-7 {
    width: 58%;
    float: left;
  }
  .col-print-8 {
    width: 66%;
    float: left;
  }
  .col-print-9 {
    width: 75%;
    float: left;
  }
  .col-print-10 {
    width: 83%;
    float: left;
  }
  .col-print-11 {
    width: 92%;
    float: left;
  }
  .col-print-12 {
    width: 100%;
    float: left;
  }
  .ministry {
    page-break-inside: avoid;
  }
  .content-header {
    display: block !important;
  }
  .content {
    padding-left: 0;
  }
  .content-header h1 {
    display: inline-block;
  }
  .created {
    font-size: 18px;
    margin-left: 10px;
    display: inline-block;
  }
  .table.giftstable > tbody > tr:nth-child(-n+3) td {
    background: #f4f70e !important;
    font-weight: bold;
  }
  .remove-left-pad-print {
    padding-left: 0;
  }
  .table.giftstable > tbody > tr > td,
  .table.giftstable > tbody > tr > th,
  .table.giftstable > tfoot > tr > td,
  .table.giftstable > tfoot > tr > th,
  .table.giftstable > thead > tr > td,
  .table.giftstable > thead > tr > th {
    padding: 3px;
  }
  .collect-talents div[class*="icheckbox"] {
    border: 1px solid #ccc;
  }
  .collect-talents [data-toggle="tooltip"] {
    display: none;
  }
  .collect-talents .print-name {
    display: inline-block !important;
    margin-bottom: 20px;
  }
  .collect-talents .print-name:after {
    content: ' ';
    display: inline-block;
    border-bottom: 1px solid #ccc;
    width: 500px;
    margin-left: 5px;
  }
}
